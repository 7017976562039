export const DEBUG_ENABLED_NAME = "viewport_debug";
export const DEBUG_LEVEL_COOKIE = "viewport_debug_level";
export const LOG_LEVEL_COOKIE = "viewport_log_level";

export enum DEBUG_LEVEL {
  SS = "SS",
  GA = "GA",
  JT = "JT",
  TRACKING = "TRACKING",
  ALL = "ALL",
}

export enum LOG_LEVEL {
  LOG = "LOG",
  INFO = "LOG_INFO",
  ERROR = "LOG_ERROR",
  ALL = "ALL",
}

export const TRACKING_LEVEL = ["ss", "ga", "jt"];
export const message = "VIEWPORT ";
