import { log } from "./helpers/logging/logger";

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID ?? "";

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action,
  category,
  label,
}: {
  action: any;
  category: any;
  label: any;
}) => {
  if (!window.gtag) {
    log("gtag not found");
  }
  window.gtag?.("event", action, {
    event_category: category,
    event_label: label,
  });
  log(
    { action: action },
    { location: location?.pathname.split("/")[1] || "home" },
    { destination: "GA" },
    { metadata: label },
  );
};

export const pageView = () => {
  if (!window.gtag) {
    log("gtag not found");
  }
  const payload = {
    page_title: document.title,
    page_location: location.href,
  };
  window.gtag?.("event", "page_view", payload);
  log(
    { action: "page_view", ...payload },
    { location: location?.pathname.split("/")[1] || "home" },
    { destination: "GA" },
  );
};
